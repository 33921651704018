import slideImg1 from "../assets/images/events/1.jpg";
import slideImg2 from "../assets/images/events/2.jpg";
import slideImg3 from "../assets/images/events/3.jpg";
import slideImg4 from "../assets/images/events/4.jpg";
import slideImg5 from "../assets/images/events/5.jpg";
import slideImg6 from "../assets/images/events/6.jpg";
import slideImg7 from "../assets/images/events/7.jpg";
import slideImg8 from "../assets/images/events/8.jpg";
import slideImg9 from "../assets/images/events/9.jpg";

import malik from "../assets/images/team/Malik.jpg";
import mabel from "../assets/images/team/Mabel.jpg";
import marilyn from "../assets/images/team/marilyn.jpg";
import zabari from "../assets/images/team/zabari1.jpg";
import rich from "../assets/images/team/rich2.jpg";
import Kissiwaa from "../assets/images/team/kisiwaa.JPG";
import AdjeiBoakye from "../assets/images/team/Adjei-Boakye.jpg";

import mildred1 from "../assets/images/events/mildred/mildred1.jpeg";
import mildred2 from "../assets/images/events/mildred/mildred2.jpeg";
import mildred3 from "../assets/images/events/mildred/mildred3.jpeg";
import dys1 from "../assets/images/events/DerkpalYirmuo/Derkpal_Yirmuo.jpg";
import eb1 from "../assets/images/events/Emmanuel_Bonyuoruku/Emmanuel_Bonyuoruku.jpg";
import siegyiere1 from "../assets/images/events/siegyiere_solomon/Siegyiere_Solomon2.jpg";
import siegyiere2 from "../assets/images/events/siegyiere_solomon/Siegyiere_Solomon.jpg";
import nb1 from "../assets/images/events/NurupaaleBernice/Nurupaale_Bernice.jpg";
import james1 from "../assets/images/events/james/james.jpg";
import tb1 from "../assets/images/events/TubdaarBertha/Tubdaar_Bertha.jpg";

import wisdom1 from "../assets/images/events/brodi/wisdom1.jpg";
import wisdom2 from "../assets/images/events/brodi/wisdom2.jpg";
import wisdom3 from "../assets/images/events/brodi/wisdom3.jpg";
import ap1 from "../assets/images/events/brodi/AnanePrincess1.jpg";
import ap2 from "../assets/images/events/brodi/AnanePrincess2.jpg";
import kr1 from "../assets/images/events/brodi/kofi_ransford1.jpg";
import kr2 from "../assets/images/events/brodi/kofi_ransford2.jpg";
import kr3 from "../assets/images/events/brodi/kofi_ransford3.jpg";
import kr4 from "../assets/images/events/brodi/kofi_ransford4.jpg";
import precious1 from "../assets/images/events/brodi/precious1.jpg";
import precious2 from "../assets/images/events/brodi/precious2.jpg";
import pyy1 from "../assets/images/events/brodi/PrincessYeliYaa1.jpg";
import pyy2 from "../assets/images/events/brodi/PrincessYeliYaa2.jpg";
import andrews1 from "../assets/images/events/brodi/andrews1.jpg";
import andrews2 from "../assets/images/events/brodi/andrews2.jpg";

import eventImg1 from "../assets/images/events/1.jpg";
import eventImg2 from "../assets/images/events/2.jpg";
import eventImg3 from "../assets/images/events/3.jpg";

import defaultImg1 from "../assets/images/default/book-mockup.jpg";
import defaultImg2 from "../assets/images/default/hes-logo-1.jpg";

import hopeImg from "../assets/images/hope.png";
import changeImg from "../assets/images/circular-image.png";
import hesIconImg from "../assets/images/web.png";

import memberImg from "../assets/images/team/MG_8882.jpg";
import volunImg from "../assets/images/team/MG_8662.jpg";



export const homeContent = {
  heroTitle: "give the child the gift of education",
  heroContent: `Empower a child's future by supporting their educational journey. At Hestias Foundation, we believe that education is the most valuable gift we can give. Your contribution helps provide essential resources and opportunities for children in need, unlocking their potential and transforming their lives. Join us in making education a reality for every child.`,
  whatWeDoSection: {
    header: "What we do",
    textContent: `At Hestias Foundation, we are dedicated to one mission: ensuring less privileged children have access to education. We fight against the barriers that hold them back, providing the support and opportunities they need to learn, grow, and create a better future. Education is their right, and we are here to make it a reality.`,
    image: hesIconImg,
  },
  hopeSection: {
    header: "you're the hope of others",
    textContent: `At Hestias Foundation, we believe that while we are dedicated to helping less privileged children access education, you too are the hope for others. By connecting with us, you become part of a movement that empowers lives, breaks cycles of poverty, and builds brighter futures. Together, we create lasting change.`,
    image: hopeImg,
  },
  changingLivesSection: {
    header: "changing lives with knowledge",
    textContent: `At the heart of what we do at Hestias Foundation. We believe that education is the most powerful tool for transforming futures. By providing less privileged children with access to learning, we equip them with the knowledge to break free from poverty, empowering them to change their lives and their communities. Together, we are creating a world where knowledge leads to opportunity and lasting change.`,
    image: changeImg,
  },
  featuredCampaignSection: {
    header: "featured campaigns",
    textContent: `At Hestias Foundation, our featured campaigns highlight the urgent and impactful work we do to support less privileged children’s education. Each campaign is a step toward breaking the cycle of poverty and ensuring brighter futures. From providing school supplies and scholarships to building safe learning environments, our campaigns focus on real change through education. Join us in making a lasting difference—your support can transform lives.`,
  },
};

export const programsContent = {
  heroText: "programs",
  projectSection: {
    textContent: ` Our programs support children in staying in school by addressing their specific needs, whether providing partial help like school supplies or full care including food, shelter, and education. For children with no one, we offer a safe home through our foundation. Beyond this, we run initiatives to assist underprivileged communities, creating opportunities for a brighter future.`,
  },
  accordionData: [
    {
      title: "Education for all",
      content: [
        {
          subtitle: null,
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
      ],
    },
    {
      title: "Every child deserves equal opportunity",
      content: [
        {
          subtitle: null,
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
      ],
    },
    {
      title: "Food for all",
      content: [
        {
          subtitle: null,
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
      ],
    },
    {
      title: "Clean water for schools",
      content: [
        {
          subtitle: null,
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
      ],
    },
  ],
};

export const aboutUsContent = {
  heroText: "who we are",
  aboutSection: {
    aboutTextContent: `Hestias Foundation is a non-profit organization dedicated to breaking down barriers to education for underprivileged children. We provide essential support to those who face significant challenges in continuing their education. Our mission is to not only offer immediate assistance but also to inspire and empower these children to contribute back to their communities, fostering a cycle of support and positive change.
    
    At Hestias Foundation, we are dedicated to one mission: ensuring less privileged children have access to education. We fight against the barriers that hold them back, providing the support and opportunities they need to learn, grow, and create a better future. Education is their right, and we are here to make it a reality.`,
    visionStatement: `Empowering young minds to shape a brighter future, where every child has access to quality education and a nurturing environment to thrive.
`,
    missionStatement: `To transform lives by expanding access to superior education, advocating for children's well-being, and championing innovative approaches that address the multifaceted challenges of tomorrow. Our mission is to nurture communities where every child can realize their full potential and contribute to a sustainable and prosperous future.`,
    image: defaultImg1,
  },
  coreValuesSection: {
    valueHeader1: "Child-Centered Commitment",
    valueTextContent1: ` We prioritize the holistic development and well-being of children, ensuring their needs guide every decision we make.
`,
    valueHeader2: "Universal Access to Education",
    valueTextContent2: ` We are dedicated to the principle that high-quality education is an inalienable right, transcending socio-economic, cultural, and geographical boundaries
`,
    valueHeader3: "Innovation-Driven Impact",
    valueTextContent3: ` We harness the power of creativity and forward-thinking collaborations to pioneer solutions that create lasting, positive change.

`,
    valueHeader4: "Empowerment Through Education",
    valueTextContent4: ` We believe in equipping children, families, and communities with the knowledge, skills, and resources necessary to drive their own success and resilience.

`,
    valueHeader5: "Compassionate Action",
    valueTextContent5: ` Our approach is rooted in empathy and kindness, with a steadfast commitment to making a meaningful difference in the lives we touch.
`,
  },
};

export const membershipContent = {
  heroText: "Membership",
  familySection: {
    header: "we are \n family",
    subTitle: "Together we can do more",
    textContent: `Join Hestias Foundation as a member and be part of a transformative movement. Your membership empowers us to reach more children, expand our programs, and create greater impact. Together, we can achieve more, break down barriers, and ensure that every child has the opportunity for a brighter future. Your support is crucial—let’s make a difference together.`,
    image: memberImg,
  },
  volunteerSection: {
    header: "volunteer",
    subTitle: "Extend a helping hand",
    textContent: `Lend your support and make a difference with Hestias Foundation. By volunteering, you help us provide crucial educational opportunities to less privileged children. Extend a helping hand and be part of a meaningful journey towards a brighter future for all.`,
    image: volunImg,
  },
};

const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);

export const event = [
  {
    id: "1",
    image: tb1,
    title: "Tubdaar Bertha",
    details:
      `Tubdaar Bertha, a 12-year-old girl from Danko, is a student who faces significant challenges in her education due to her family’s financial difficulties. Both of her parents, Tubdaar Maurice and Tubdaar Gertrude, are alive but unable to provide for her needs. Her mother does not work, and her father relies on selling lottery tickets for a living, which is not enough to support their family.

Thanks to the kindness of a Good Samaritan, Bertha is fortunate enough to have a uniform, but she still lacks other essential school supplies. She is in desperate need of stationery, a school bag, shoes or sandals, and textbooks to continue her education. Despite these hardships, Bertha remains determined to learn and would greatly benefit from any support to ensure her future success.`,
    location: "Danko",

    date: {
      day: "14",
      month: "september",
      year: "2022",
    },

    youtubeURL: "",
    yt_title:
      "",
    pictures: [
      { img: tb1 },
   
    ],
    featured: false,
    project: true,
  },
  {
    id: "2",
    image: james1,
    title: "James",
    details:
     `James, a 9-year-old boy from Monyupelle, attends Monyupelle M/A Primary School but faces significant challenges in continuing his education. After the loss of his father, the responsibility of raising him fell solely on his mother, Bozie Esther, a small-scale farmer. Her limited income is barely enough to cover their basic needs, leaving no room for essentials like school supplies or clothing.

James urgently needs items such as stationery, a school bag, a uniform, and shoes or sandals to continue his education. Despite these hardships, he remains eager to learn, and with support, he can have a better chance at a brighter future.`,
    location: "Monyupelle",

    date: {
      day: "19",
      month: "september",
      year: "2022",
    },

    youtubeURL: "",
    yt_title:
      "",
    pictures: [
      { img: james1 },
   
    ],
    featured: false,
    project: true,
  },
  {
    id: "3",
    image: nb1,
    title: "Nurupaale Bernice",
    details:
     `
     Nurupaale Bernice, a 12-year-old student at Monyupelle M/A JHS, comes from a family facing severe financial struggles. Her parents, Anthony Nurupaale and Esther Siemoglu, are subsistence farmers with limited income. While both parents are alive, the father’s irresponsibility has placed the full burden of providing for the family on her mother.

Despite her mother’s efforts, meeting Bernice’s basic needs, such as a school uniform, bag, stationery, and shoes, has become a challenge. This has made life difficult for Bernice, but her determination to continue her education remains strong. With support, Bernice can overcome these obstacles and stay on her path toward a brighter future.
     `,
    location: "Monyupelle",

    date: {
      day: "19",
      month: "september",
      year: "2022",
    },

    youtubeURL: "",
    yt_title:
      "",
    pictures: [
      { img: nb1 },

    ],
    featured: false,
    project: true,
  },
  {
    id: "4",
    image: dys1,
    title: "Derkpal Yirmuo Simon",
    details:
      `
      Derkpal Yirmuo Simon, a 13-year-old from Baseble, currently living in Pataal (Nandom), faces significant challenges after the passing of his father in late 2021. His family’s stability was shaken, leaving the responsibility of providing for Simon and his two siblings on their jobless mother, Mary Magdalene.

Simon, a student at St. Maria R/C JHS, often goes to school on an empty stomach, waiting until the end of the day to have his only meal at home. With three siblings to care for, including an older one in senior high school, their mother struggles to meet their basic needs. Simon urgently needs essentials like books, clothes, a school bag, and shoes or sandals to continue his education.

Despite the hardships, Simon remains eager to learn, but he needs support to overcome these barriers and build a better future.
      `,
    location: "Baseble",

    date: {
      day: "19",
      month: "september",
      year: "2022",
    },

    youtubeURL: "",
    yt_title:
      "",
    pictures: [
      { img: dys1 },
   
    ],
    featured: false,
    project: true,
  },
  {
    id: "4",
    image: eb1,
    title: "Emmanuel Bonyuoruku",
    details:
     `Emmanuel Bonyuoruku has faced abandonment and immense hardship since birth. He has never seen his father, who left shortly after his birth when Emmanuel’s mother became pregnant with another man’s child. Following this, his mother left him with his grandmother and disappeared, never returning to check on him.

For years, Emmanuel’s aging grandmother cared for him, but as she could no longer manage, his uncle—a teacher and her twin—stepped in to support him. Tragically, his uncle passed away earlier this year, leaving Emmanuel without any means of support.

This loss forced Emmanuel to stop attending school. He struggles daily, often surviving on water for weeks, with inadequate clothing and a crumbling shelter. His situation is dire, and he urgently needs help to rebuild his life and resume his education.`,
    location: "",

    date: {
      day: "19",
      month: "september",
      year: "2022",
    },

    youtubeURL: "",
    yt_title:
      "",
    pictures: [
      { img: eb1 },
     
    ],
    featured: false,
    project: true,
  },
  {
    id: "5",
    image: siegyiere1,
    title: "Siegyiere Solomon",
    details:
     `
     Siegyiere Solomon is a 16-year-old boy from Nandom, currently staying in Konyugangn. His life has been marked by challenges. Raised by a single mother, Siegyiere Pauline, he and his siblings faced many hardships. To ease the burden on their mother, his aunt, Delle Bridget, took him to live with their grandmother. Unfortunately, his grandmother has since passed away.

Siegyiere’s father, Siegyiere Paul, is still alive, but his whereabouts are unknown, leaving the family without additional support. Despite these struggles, Solomon is determined to pursue his education, but he urgently needs help. His current needs include a school uniform, a bag, shoes or sandals, and textbooks to continue his studies.

This is where Hestias Foundation can make a life-changing impact, ensuring that Solomon’s education is not derailed by financial difficulties and giving him a chance for a brighter future.
     `,
    location: "Nandom",
    date: { day: "14", month: "september", year: "2022" },
    youtubeURL: "",
    yt_title:
      "",
    pictures: [
      { img: siegyiere1 },
      { img: siegyiere2 },
      
    ],
    featured: true,
    project: true,
  },
  {
    id: "6",
    image: mildred2,
    title: "Mildred Aanianya: A Story of Resilience and Hope",
    details: `
Mildred Aanianya, a 15-year-old girl from Monyupelle in the Nandom District, has endured a life filled with hardships from birth. Born to parents who abandoned her at an early age, Mildred was raised by her grandmother, who became her sole guardian. Tragically, her grandmother passed away when Mildred was just in Basic 3, leaving her with no family support or knowledge of any living relatives.

Since then, Mildred has had to fend for herself. Without any stable source of support, she followed friends from region to region, eventually settling in Nandom. Currently, she washes bowls for a local food vendor to earn her daily bread.

Despite her challenges, Mildred has a burning desire to return to school and pursue an education. She dreams of creating a better future for herself but lacks the resources to take this important step. She urgently needs support to acquire school uniforms, books, a bag, shoes, and other essentials to enroll and continue her education.

With your help, Mildred can turn her story of struggle into one of hope and success. Your support can give her the chance to realize her dreams and build a brighter future.



Hestias Foundation’s Commitment

At Hestias Foundation, we believe that every child deserves the chance to dream, learn, and thrive. We are committed to taking full responsibility for Mildred’s education and well-being by:
	•	Enrolling her in a boarding school.
	•	Providing her with school uniforms, books, a bag, shoes/sandals, and other essentials.
	•	Ensuring she has a safe and supportive environment, including accommodation and meals.
	•	Offering full care and mentorship to help her succeed academically and personally.

Call for Support
To fulfill this commitment and give Mildred the opportunity she deserves, we need your help. We are seeking donations to cover her educational needs, boarding school fees, and overall care. Your contribution, no matter how small, will directly impact Mildred’s life by enabling her to access quality education and a brighter future.

How You Can Help
By donating to Hestias Foundation, you can be a part of Mildred’s transformation story. Your support will:
	•	Break the cycle of hardship she has faced since birth.
	•	Equip her with the tools and skills she needs to succeed.
	•	Empower her to become a role model and inspiration for others.

Conclusion
Mildred’s determination and resilience inspire us to believe in the power of education to change lives. Together, we can give her the chance to thrive and build a future filled with hope.

To contribute to Mildred’s journey, please donate today through our website or contact us for more details on how you can help.

Thank you for being a part of this life-changing mission.

Let’s build a brighter future for Mildred, one step at a time.`,
    location: "Nandom",
    date: { day: "19", month: "september", year: "2022" },
    youtubeURL: "",
    yt_title: "",
    pictures: [{ img: mildred1 }, { img: mildred2 }, { img: mildred3 }],
    featured: true,
    project: true,
  },
  {
    id: "7",
    image: kr1,
    title: "Kofi Ransford",
    details:
      `Kofi Ransford, a 14-year-old student from Brodi, is the son of Anane Isaac and Mercy Amoako. He has distinguished himself by excelling in the Hestias Foundation’s competitive scholarship examination, earning a fully-funded scholarship. This prestigious award covers all of his educational expenses, from tuition to essential supplies, ensuring he can devote himself entirely to his academic pursuits. With this support, Kofi is empowered to focus on achieving his goals, unencumbered by financial challenges.`,
    location: "Brodi",
    date: { day: "19", month: "september", year: "2022" },
    youtubeURL: "",
    yt_title:
      "",
    pictures: [
      { img: kr1 },
      { img: kr2 },
      { img: kr3 },
      { img: kr4 },
     
    ],
    featured: true,
    project: true,
  },
  {
    id: "8",
    image: andrews1,
    title: "Andrews Yentinga",
    details:
      `Andrews Yentinga, a 13-year-old from Brodi, is the son of Peter Mensah and Vivian Anane. After achieving outstanding results in the Hestias Foundation’s scholarship examination, he was awarded a full scholarship. This prestigious award will cover all of his educational expenses, including uniforms, books, and essential supplies, providing him with the support needed to fully concentrate on his studies and pursue his academic ambitions without the burden of financial limitations.`,
    location: "Brodi",
    date: { day: "19", month: "september", year: "2022" },
    youtubeURL: "",
    yt_title:
      "",
    pictures: [
      { img: andrews1 },
      { img: andrews2 },
    ],
    featured: false,
    project: true,
  },
  {
    id: "9",
    image: precious1,
    title: "Precious Abena Arthur",
    details:
      `Precious Abena Arthur, a dedicated JHS 1 student at SDA in Brodi, is the daughter of Arthur Martin and Adwoa Mensah. After excelling in the Hestias Foundation’s scholarship examination, she has been awarded full sponsorship. This scholarship will cover all her academic requirements, including uniforms, books, and other necessary materials, ensuring she can devote her energy to her studies and work towards achieving her educational goals without financial barriers.`,
    location: "Brodi",
    date: { day: "19", month: "september", year: "2022" },
    youtubeURL: "",
    yt_title:
      "",
    pictures: [
      { img: precious1 },
      { img: precious2 },
    ],
    featured: false,
    project: true,
  },
  {
    id: "10",
    image: ap1,
    title: "Anane Princess",
    details:
      `Anane Princess, supported by her guardian, Peh Eric, and her mother, Akua Kuma Lydia, has successfully qualified for the Hestias Foundation’s scholarship program. After demonstrating exceptional performance in our scholarship examination, she has been granted a full scholarship. This comprehensive support will cover all of her educational expenses, including tuition, school supplies, and other essential needs, allowing her to dedicate herself fully to her studies and work towards a brighter, more promising future.`,
    location: "Brodi",
    date: { day: "19", month: "september", year: "2022" },
    youtubeURL: "",
    yt_title:
      "",
    pictures: [
      { img: ap1 },
      { img: ap2 },
    ],
    featured: false,
    project: true,
  },
  {
    id: "11",
    image: pyy1,
    title: "Princess Yeli Yaa",
    details:
      `Princess Yeli Yaa, under the guardianship of her aunt, Esther Bediako, and father, Andrew Mensah, has been selected for the Hestias Foundation scholarship program. Having excelled in our scholarship examination, she has received a full scholarship to cover all her educational expenses. This invaluable support empowers her to fully focus on her studies, paving the way for a successful and promising future.`,
    location: "Brodi",
    date: { day: "19", month: "september", year: "2022" },
    youtubeURL: "",
    yt_title:
      "",
    pictures: [
      { img: pyy1 },
      { img: pyy2 },
    ],
    featured: false,
    project: true,
  },
  {
    id: "12",
    image: wisdom1,
    title: "Wisdom Amanfo",
    details:
      `Wisdom Amanfo, a dedicated 15-year-old student, is the son of Patrick Gbonam and Georgina Nchifie. After demonstrating exceptional academic performance in the Hestias Foundation’s scholarship examination, Wisdom emerged as one of the top candidates in his class. As a result, he has been awarded a full scholarship by the Hestias Foundation. This scholarship will cover all of his educational expenses, including tuition, books, and other essential materials, providing him with the opportunity to continue his studies without the financial burden and focus on achieving his academic goals.`,
    location: "Brodi",
    date: { day: "19", month: "september", year: "2022" },
    youtubeURL: "",
    yt_title:
      "",
    pictures: [
      { img: wisdom1 },
      { img: wisdom2 },
      { img: wisdom3 },
    ],
    featured: false,
    project: true,
  },
];

export const events = shuffle(event);

export const upcomingEvents = [
  {
    id: "1",
    image: defaultImg2,
    title: "School for all",
    details: `We are dedicated to supporting children who have dropped out of school due to financial difficulties, as well as those at risk of leaving school for the same reason. We remind everyone: ‘School is for all’—together, we can make education accessible to every child. `,
  },
  {
    id: "2",
    image: defaultImg1,
    title: "Books for all",
    details: `We are committed to empowering minds by providing free books to those who seek knowledge or support, fostering equal access to learning for all.`,
  },
];



export const sliderImages = [
  {
    image: slideImg1,
  },
  {
    image: slideImg2,
  },

  {
    image: slideImg3,
  },
  {
    image: slideImg4,
  },
  {
    image: slideImg5,
  },
  {
    image: slideImg6,
  },
  {
    image: slideImg7,
  },
  {
    image: slideImg8,
  },
  {
    image: slideImg9,
  },
  {
    image: slideImg5,
  },
];

export const testimonies = {
  testimonyHeader: "what people say !  ",
  testimonyTitle: "testimonials",
  testimonials: [
    {
      id: "0",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Ruth Akotompaya",
      role: "Beneficiary",
      avatar: "",
    },
    {
      id: "1",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Asabea Jude",
      role: "Beneficiary",
      avatar: "",
    },
    {
      id: "2",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Yaw Bompata",
      role: " beneficiary",
      avatar: "",
    },
    {
      id: "3",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Black Moses ",
      role: " Beneficiary",
      avatar: "",
    },
    {
      id: "4",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Hajia Bintuphobia ",
      role: "Beneficiary",
      avatar: "",
    },
    {
      id: "5",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Jane Doe ",
      role: " Beneficiary",
      avatar: "",
    },
    {
      id: "6",
      testimony: ` Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "John Doe ",
      role: " Beneficiary",
      avatar: "",
    },
  ],
};

export const teamMembers = [
  {
    id: "1",
    name: "Richard Anane Asiamah",
    role: "Founder and Director",
    image: rich,

    info: `Richard Anane Asiamah is the founder and director of the Hestias Foundation, an organization dedicated to advancing educational opportunities for children in Ghana. After working as a teacher in Ghana and now residing in the UK, Richard has witnessed the profound impact that financial and logistical challenges can have on a child's ability to attend school.

Motivated by a deep concern for these vulnerable children who were forced to leave school due to lack of support, Richard established the Hestias Foundation. The organization aims to provide crucial educational assistance to those in need, ensuring that every child in Ghana has the opportunity to pursue their education and achieve their full potential.

Richard's vision is not only to help these children succeed but also to inspire a future generation that will, in turn, support others. Through the Hestias Foundation, Richard is dedicated to fostering a culture of mutual aid and community support, embodying the principle that by helping one another, we can build a brighter future for all.`,
  },
  {
    id: "2",
    name: "Anacetus Zabari Jnr",
    role: "HR Manager",
    image: zabari,

    info: `A creative and innovative leader with expertise in business management, customer engagement, and business development. His vision is to drive human capital development by unlocking the potential of young people, fostering true leadership, education, and entrepreneurship. As an aspiring entrepreneur, educator, and impact speaker, Anacetus is committed to developing innovative solutions to empower communities. His strong communication and relationship management skills, alongside his proficiency in leveraging social media for growth, align perfectly with Hestias Foundation's mission to provide education for underprivileged children, helping them grow and give back to those in similar situations.`,
  },
  {
    id: "3",
    name: "Marilyn Asuamah",
    role: "Director",
    image: marilyn,

    info: `Marilyn Asuamah is a director at the Hestias Foundation, bringing her dedication and expertise as a UK-based nurse and continuing student to the organization. With a deep commitment to the mission of Hestias Foundation, Marilyn plays a crucial role in advancing the charity's goals of providing educational support to children in Ghana.

Her background in nursing and ongoing academic pursuits reflect her passion for service and personal growth. Marilyn's work with the Hestias Foundation aligns with her belief in the transformative power of education and her desire to make a meaningful impact on the lives of children facing educational barriers.

Through her role, Marilyn contributes to the foundation's efforts to ensure that every child in Ghana has access to the education they deserve, fostering a cycle of support and empowerment that extends beyond the classroom.`,
  },

  {
    id: "4",
    name: "Malik Benjamin",
    role: "Program Director",
    image: malik,

    info: `Malik Benjamin is a passionate and driven leader with a BSc in Biological Sciences. Currently serving as the Program Director of Hestias Foundation, he brings exceptional leadership and a deep commitment to empowering underprivileged children. Alongside his role, Malik is the President of the National Service Personnel Association, Tema West Municipal, demonstrating his dedication to community service. Beyond his professional life, he is a creative individual who expresses himself through music and personal growth, always striving for greatness and inspiring those around him.`,
  },
  {
    id: "5",
    name: "Mabel Obour",
    role: "General Secretary",
    image: mabel,

    info: ` As the General Secretary of Hestias Foundation, I am passionate about supporting and uplifting underprivileged children, helping them reach their full potential. Alongside my work as a midwife, I am deeply committed to the foundation's mission. In this role, I ensure the smooth operation of our programs and contribute to the positive impact we strive to make in the lives of the children we serve.`,
  },
  {
    id: "6",
    name: "Belinda Adjei-Boakye",
    role: "Assistant HR",
    image: AdjeiBoakye,

    info: ` Belinda Adjei-Boakye is a graduate and entrepreneur specializing in wig-making and perfumery. She holds a Bachelor's degree in Political Science with Social Studies Education from the University of Education, Winneba. Passionate about cooking, helping others, and music, Belinda aligns with Hestias Foundation's mission to support underprivileged children through education, encouraging them to give back to their communities. She now contributes to this vision as the Assistant HR at Hestias Foundation.`,
  },
  {
    id: "7",
    name: "Belinda Kissiwaa",
    role: "Financial Manager",
    image: Kissiwaa,

    info: ` Belinda Kissiwaa is a hardworking, kind, and dedicated individual serving as the Financial Manager of Hestias Foundation. Inspired to support underprivileged children in their academic journeys, she brings her commitment to education alongside her profession as a nurse. Belinda ensures that her contributions align with the foundation’s vision and mission to empower less privileged children, encouraging them to give back to their communities.`,
  },
];

export const applyContent = {
  heroText: "Apply for Sponsorship",
  welcomeStatement: `“Hestias Foundation is dedicated to supporting children who have dropped out of school or are at risk of dropping out due to financial hardships, loss of parents, or inability of guardians to provide. Our mission is to empower these children through education, enabling them to give back to society in the future. If you or someone you know needs help, apply for our scholarship today.`,
  eligibilityCliteria: {
    title: "Eligibility Criteria",
    subTitle: "Who can apply",
    cliteriaList: [
      "Children who have already dropped out of school.",
      "Children at risk of dropping out due to financial struggles.",
      "Orphans or children whose parents/guardians cannot afford their education.",
    ],
    privacyAssurance: `All personal information will be handled securely and used only for scholarship evaluation purposes.`,
  },
};
